<template>
  <v-form @submit.stop.prevent="checkForm()" ref="form" class="mt-5">
    <v-row align="center" class="ml-0">
      <h5>
        {{ `${$t("alpha_register")}: ${user_prop.AlphaRegisterUrl}` }}
      </h5>
      <v-btn
        x-small
        icon
        class="ml-3"
        @click="gs.copy_url_to_clipboard(user_prop.AlphaRegisterUrl)"
      >
        <v-icon color="primary">mdi-content-copy</v-icon>
      </v-btn>
    </v-row>
  </v-form>
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "ManageUserBanker",
  components: {},

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    user: {},
  }),

  computed: {},
  props: {
    user_prop: Object,
  },

  async created() {
    this.alert_msg = null;
    if (this.user_prop != null) {
      this.user = this.user_prop;
    }
  },

  methods: {},
};
</script>
